import React, {useEffect, useState} from "react";
import TabListItem from "../atoms/tab-list-item";
import TabsNavList from "../atoms/tabsNavList";
import TabPane from "../atoms/tabPane";
import TabsContent from "../atoms/tabsContent";
import ItemDetailHeader from "../atoms/item-detail-header";
import ItemsDetailNumbers from "../atoms/items-detail-numbers";
import Button from "../atoms/button";
import BidListItem from "../molecules/bidListItem";
import HistoryListItem from "../molecules/historyListItem";
import {useSystem} from "../../hooks/use-system";
import ItemDetailFooter from "../atoms/item-detail-footer";
import {useParams} from "react-router-dom";
import callAPI from "../../api";
import {AccountStakedAmount, GetListingInfo, MinStakeAmount, UseContractMethod} from "../../hooks/use-dapp";
import {useContract} from "../../hooks/use-contract";
import {useEthers} from "@usedapp/core";
import config from "../../config";
import web3 from "web3";
import {useNavigate} from "react-router";


function ItemDetail() {

    const system = useSystem();
    const contract = useContract();

    const navigate = useNavigate();

    const {account} = useEthers();

    let {collection, tokenId} = useParams();

    const [nft, setNFT] = useState(null);
    const [bids, setBids] = useState([]);
    const [history, setHistory] = useState([]);
    const [claiming, setIsClaiming] = useState(false);
    const [trxMessage, setTrxMessage] = useState("");

    const minimumStakeAmount = web3.utils.fromWei(MinStakeAmount());
    const accountStakedAmount = web3.utils.fromWei(AccountStakedAmount(account));

    const canBidInExclusive = accountStakedAmount >= minimumStakeAmount;


    useEffect(() => {
        if (nft == null)
            getNFT();
            getNFT();
    }, [collection, tokenId]);

    const getNFT = () => {
        callAPI({collection: collection, tokenId: tokenId, listed: true}, "getNFT").then((resp) => {
            if (resp.status === 'SUCCESS') {
                setNFT(resp.payload.nft);
                setBids(resp.payload.bids);
                setHistory(resp.payload.history);
            }
        })
    }

    const listingInfo = GetListingInfo(nft?.listing?.list_id);

    const auctionEnded = listingInfo && listingInfo.endTime && listingInfo.endTime * 1000 <= Date.now();
    const accountIsHighestBidder = account && account == listingInfo?.highestBidder;

    const {
        state,
        send,
        events
    } = UseContractMethod(contract?.auctionHouse, "claimNFT");

    useEffect(() => {
        if (state) {

            if (state.status === 'Exception') {
                setIsClaiming(false);
            } else if (state.status === 'Mining') {
                setTrxMessage('🔐 Transaction signed! Sending your NFT... \n Please don not close this page');
            } else if (state.status === 'Success') {
                setTrxMessage(`Congratulations! Your <a href='https://${config.Ethscan}${state.transaction.hash}'}>transaction</a> is confirmed and the asset will arrive in your wallet shortly.`);
                callAPI({list_id: listingInfo.list_id, trx_hash: state.transaction.hash}, 'setClaimed');
            }

        }
    }, [state, events])


    const description = 'Lorem ipsum dolor sit amet...'
    useEffect(() => {
        document.getElementById('wrapper').style.backgroundColor = '#141414'
    }, [])



    return (
        <div className="container" style={{marginTop: 120}}>
            <div className="item_details mt-5">
                <div className="row sm:space-y-20">
                    <div className="col-12 col-md-6 text-center">
                        <img className="item_img noCover" src={nft?.img} alt=""></img>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="space-y-20">
                            <ItemDetailHeader
                                cardName={nft?.title}
                                collectionSize={1}
                                owner={nft?.collection?.name} //data.owner
                            />
                            <div className="box bg-dark">
                                <div className="space-y-20">
                                    <div className="d-flex-column d-md-flex mb-3 justify-content-between mb-30_reset">
                                        <TabsNavList>
                                            <TabListItem
                                                classNames="btn btn-white btn-sm active"
                                                id="#tabs-1"
                                                ariaSelected
                                            >
                                                Details
                                            </TabListItem>
                                            <TabListItem
                                                classNames="btn btn-white btn-sm"
                                                id="#tabs-2"
                                            >
                                                Bids
                                            </TabListItem>
                                            <TabListItem
                                                classNames="btn btn-white btn-sm"
                                                id="#tabs-3"
                                            >
                                                History
                                            </TabListItem>
                                        </TabsNavList>
                                    </div>
                                    <div className="hr"></div>
                                    <TabsContent>
                                        <TabPane active id="tabs-1">
                                            {nft?.description}
                                            {nft?.unlockable_content ? (
                                                <>
                                                    <div className="my-3"></div>
                                                    <p>Your unlockable content <a href={nft.unlockable_content} target={'_new'}
                                                                               style={{
                                                                                   textDecoration: 'underline !important',
                                                                                   fontWeight: 'bold'
                                                                               }}>here.</a></p>
                                                </>
                                            ) : (
                                                null
                                            )}

                                        </TabPane>
                                        <TabPane id="tabs-2">
                                            {bids.map((b) => {
                                                return <BidListItem b={b} listingInfo={listingInfo} style={{
                                                    borderBottom: '1px solid #ffffff',
                                                    borderBottomLeftRadius: '0',
                                                    borderBottomRightRadius: '0',
                                                    paddingBottom: '10px',
                                                    marginBottom: '10px'
                                                }}/>
                                            })}
                                            {bids.length == 0 && <p>
                                                No Bids placed!
                                            </p>}
                                        </TabPane>
                                        <TabPane id="tabs-3">
                                            {history.map((h) => {
                                                return <HistoryListItem h={h}/>
                                            })}
                                        </TabPane>
                                    </TabsContent>
                                </div>
                            </div>
                            <ItemsDetailNumbers
                                listingInfo={listingInfo}
                            />
                            {/* <div className="hr2"></div> */}
                            <ItemDetailFooter nft={nft}/>
                            <div className="d-flex space-x-20">
                                {listingInfo && listingInfo.list_id ? (
                                        auctionEnded ? (
                                                accountIsHighestBidder ? (

                                                    claiming ? (
                                                        <>
                                                        {state.status==='Mining' && <i className="ri-donut-chart-fill md spin mr-2 text-primary"></i>}
                                                        <p className="text-light" style={{fontWeight: 'bold'}} dangerouslySetInnerHTML={{__html: trxMessage}}></p>
                                                        </>
                                                    ) : (
                                                        nft?.listing?.claimed_dt ? (
                                                            <p>Your NFT has been claimed
                                                                on {new Date(nft.listing.claimed_dt).toLocaleString()}</p>
                                                        ) : (
                                                            <Button
                                                                href=""
                                                                classNames="btn btn-primary btn-lg"
                                                                onClick={() => {
                                                                    send(listingInfo.list_id);
                                                                    setIsClaiming(true);
                                                                    setTrxMessage('Please sign the transaction...')
                                                                }}
                                                            >
                                                                Claim
                                                            </Button>
                                                        )

                                                    )

                                                ) : (null)
                                            )
                                            :
                                            (
                                                <>
                                                    { (!listingInfo.isExclusive || (listingInfo.isExclusive &&
                                                            canBidInExclusive)) &&
                                                        <Button
                                                            href=""
                                                            classNames="btn btn-primary btn-lg"
                                                            data-toggle="modal"
                                                            data-target="#popup_buy"
                                                            onClick={() => account ? system.showModal('place-bid', {
                                                                listingInfo: listingInfo,
                                                                callback: getNFT
                                                            }) : system.showModal('choose-wallet')}
                                                        >
                                                            Place a Bid
                                                        </Button>
                                                    }
                                                    {listingInfo.isExclusive && !canBidInExclusive && <p>You are not
                                                        eligible to bid on this NFT! <Button onClick={() => {
                                                        navigate("/profile")}
                                                        }>Check Eligibility</Button></p>}
                                                </>
                                            )
                                    )
                                    : (
                                        null
                                    )
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ItemDetail;
