
import heroImg from '../../../assets/img/bg/slide_2.png'
import heroImgMobile from '../../../assets/img/bg/slide_2_mobile.png'
import { isMobile } from "react-device-detect"
import iai_logo from '../../../assets/img/logos/iai_logo.png'

export default function Slide2(){	
	return(
			<div className="hero__1 bg-dark mb-5 mt-5" style={{background: `url(${ isMobile ? heroImgMobile : heroImg})`,  backgroundSize: 'cover', backgroundRepeat: 'no-repeat', height: '85vh'}}>
					<div className="hero-inner h-100 my-auto">
						<div className="row align-items-center h-100 my-auto">
								<div className="d-flex flex-column justify-content-center space-y-20 h-100 my-auto">
									<img className="iai_slide_logo" src={iai_logo}/>
									<h1 className="hero__title text-center text-light mx-auto" style={{fontSize: '52px'}}>
										inheritance Art<br></br><span className="text-primary">Auction House</span>
									</h1>
									{/*<p className="text-center txt text-light mx-auto" style={{fontSize: '25px', maxWidth: '450px'}}>*/}
									{/*	Enjoy, experience & invest in extraordinary inheritance Art NFTs */}
									{/*</p>*/}
									<div className="d-flex flex-column mx-auto">
										<span className="coming-soon">BETA Version is LIVE for Testing</span>
										<div className="progress">
											<div className="progress-inner"></div>
										</div>
										<span style={{fontSize: '24px', textAlign: 'center'}}>&copy; 2022</span>
									</div>
								</div>
						</div>
			
					</div>
			</div>
	)
}