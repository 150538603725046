import React from 'react';
import Modal from '../../../atoms/modal';
import styles from './choose-wallet.module.css';
// import {
// 	connectorsByName,
// 	connectorsByWallet,
// } from '../../../../utils/web3-react';
import {useSystem} from '../../../../hooks/use-system';

import logoMetaMask from "../../../../assets/img/icons/metamask.svg"
import logoWalletConnect from "../../../../assets/img/icons/walletconnect.svg"
import config from "../../../../config";
import {useEthers} from "@usedapp/core";


function ChooseWallet({...props}) {

    const {activate, chainId} = useEthers();
    const envChainId = config.chainId
    const { error } = useEthers();

    React.useEffect(() => {
        if (chainId && window.ethereum) {
            window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0x'+envChainId }],
            });
        }
    }, [chainId, envChainId]);

    const system = useSystem();
    return (
        <Modal {...props} title="Choose Wallet">
            <div className={styles.wallets}>
                <button
                    className="btn btn-grad btn-sm mr-3 d-flex align-items-center mb-3"
                    onClick={() => {
                        activate(config.Connectors.injected);
                        localStorage.setItem(config.constants.STORAGE.CONNECTOR, 'Injected');
                        system.showModal('');
                    }}
                >
                    <div className="icon coin mr-2">
                        <img src={logoMetaMask} style={{width: '25px'}}/>
                    </div>
                    <span>Metamask</span>
                </button>
                <button
                    className="btn btn-grad btn-sm mr-3 d-flex align-items-center mb-3"
                    onClick={() => {
                        activate(config.Connectors.walletconnect);
                        system.showModal('');
                    }}
                >
                    <div className="icon mr-2">
                        <img src={logoWalletConnect} style={{width: '25px'}}/>
                    </div>
                    <span>Walletconnect</span>
                </button>
            </div>
        </Modal>
    );
}

export default ChooseWallet;
