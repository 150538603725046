import React, {useEffect, useState} from 'react'
import ProfileCover from '../molecules/profile-cover'
import ProfileHeader from '../molecules/profile-header'
import ProfileSidebar from '../organisms/profile-sidebar'
import TabListItem from '../atoms/tab-list-item'
import TabPane from '../atoms/tabPane'
import StepCard from '../molecules/step-card'
import Button from '../atoms/button'
import Dropdown from '../atoms/dropdown'
import ItemCard from '../molecules/ItemCard'
import TabsNavList from '../atoms/tabsNavList'
import TabsContent from '../atoms/tabsContent'
import BidListItem from '../molecules/bidListItem'
import fineArt5 from '../../assets/img/items/fine_art_5.jpg'
import {useSystem} from '../../hooks/use-system'
import StakeCard from '../molecules/StakeCard'

import ArtworkCard from '../molecules/ArtworkCard'
import callAPI from "../../api";
import SubmittedNFT from "../molecules/SubmittedNFT";
import {useNavigate} from "react-router";
import {useEthers} from "@usedapp/core";
import {
    IsAdmin,
    MinStakeAmount,
    AccountStakedAmount,
    BalanceOf,
    AccountStakedTime,
    LockTime
} from "../../hooks/use-dapp";
import Minting from "./minting";
import web3 from "web3";
import {useContract} from "../../hooks/use-contract";
import {prettyNumber} from "../../utils/string";
import Countdown from "../atoms/countdown";

function Profile() {

    const {account} = useEthers();
    const navigate = useNavigate();

    const contract = useContract();

    const system = useSystem();

    const [user, setUser] = useState(null);
    const [ahOwner, setAHOwner] = useState(null);

    const buyiAILinks = [
        {text: 'UniSwap', url: 'https://app.uniswap.org/#/swap?chain=rinkeby'},
    ]

    React.useEffect(() => {
        if (user === null && account) {
            callAPI({wallet: account}, "getUserStatus").then((data) => {
                if (data.payload && data.payload.subscriber)
                    setUser(data.payload.subscriber)
                else
                    setUser({'wallet': account})
            });
        }

    }, [user, account]);

    const [assets, setAssets] = useState([]);
    const [createdNFts, setCreatedNFTs] = useState([]);
    const [bids, setBids] = useState([]);

    const isAdmin = IsAdmin(account);

    const getAssets = () => {
        callAPI({}, 'assets').then(resp => {
            if (resp.status == 'SUCCESS') {
                setAssets(resp.payload.assets);
            }
        })
    }

    const getCreatedNFTs = () => {
        callAPI({}, 'createdNFTs').then(resp => {
            if (resp.status == 'SUCCESS') {
                setCreatedNFTs(resp.payload.nfts);
            }
        })
    }

    const getBids = () => {
        callAPI({}, 'userBidHistory').then(resp => {
            if (resp.status == 'SUCCESS') {
                setBids(resp.payload.history);
            }
        })
    }

    const getAHSettings = () => {
        callAPI({}, 'getAHSettings').then(resp => {
            if (resp.status == 'SUCCESS') {
                setAHOwner(resp.payload.owner_wallet);
            }
        })
    }

    const iaiBalance = web3.utils.fromWei(BalanceOf(contract?.iai, account));
    const minimumStakeAmount = web3.utils.fromWei(MinStakeAmount());
    const accountStakedAmount = web3.utils.fromWei(AccountStakedAmount(account));
    const accountStakedTime = AccountStakedTime(account);
    const lockTime = LockTime();
    const newTime = (accountStakedTime ? accountStakedTime.getTime() : 0) + lockTime*1000;
    var compareDate = new Date(newTime);
    const canUnstake = new Date() >= compareDate

    useEffect(() => {
        if (isAdmin && createdNFts.length == 0)
            getCreatedNFTs();

        if (bids.length == 0)
            getBids();

        if (assets.length == 0)
            getAssets();

        if (ahOwner === null)
            getAHSettings();

    }, [account, isAdmin]);

    return (
        <>
            <div className="mb-100">
                <div className="hero__profile">
                    <ProfileCover imgUrl={user?.profile_banner}/>
                    <ProfileHeader userName={user?.username} dp={user?.profile_dp}/>
                </div>
            </div>
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-3 col-md-7 mb-5 mb-md-0">
                        <ProfileSidebar status={true} user={user}/>
                    </div>
                    <div className="col-lg-9 col-md-12 order-md-1">
                        <div className="d-flex justify-content-between">
                            <TabsNavList>
                                {isAdmin && (
                                    <>
                                        <TabListItem classNames="btn btn-white btn-sm" id="#minting-tab">
                                            Minting
                                        </TabListItem>
                                        <TabListItem classNames="btn btn-white btn-sm" id="#ahm-tab">
                                            Auction Management
                                        </TabListItem>
                                    </>
                                )}
                                <TabListItem classNames="btn btn-white btn-sm active" id="#bid-el-tab" ariaSelectd>Bidding
                                    Eligibility</TabListItem>
                                {/*<TabListItem classNames="btn btn-white btn-sm" id="#stakes-tab">My Stakes</TabListItem>*/}
                                <TabListItem classNames="btn btn-white btn-sm" id="#assets-tab">Assets</TabListItem>
                                <TabListItem classNames="btn btn-white btn-sm" id="#bids-tab">My Bids</TabListItem>
                                {/* <TabListItem classNames="btn btn-white btn-sm" id="#tabs-4">Favorites</TabListItem> */}

                                {/* <TabListItem classNames="btn btn-white btn-sm" id="#tabs-6" ariaSelectd>My Artworks </TabListItem>*/}
                                <TabListItem classNames="btn btn-white btn-sm" id="#nft-holograms-tab"
                                             ariaSelected>
                                    NFT Holograms
                                </TabListItem>
                            </TabsNavList>
                        </div>
                        <TabsContent>
                            <TabPane active id="bid-el-tab">
                                <div className="row mb-5">
                                    <StepCard
                                        title='Step 1'
                                        text='iAI Holdings'
                                        status={(iaiBalance + accountStakedAmount) >= minimumStakeAmount}
                                        statusLabel='iAI Amount'
                                        statusValue={prettyNumber(iaiBalance)}
                                        tooltipText=''
                                        ctaButtons={[<Dropdown items={buyiAILinks} text="Buy iAI"
                                                               icon={<i className="ri-coin-line mr-2"/>}/>]}
                                    />
                                    <StepCard
                                        title='Step 2'
                                        text='Stake iAI'
                                        status={accountStakedAmount >= minimumStakeAmount}
                                        statusLabel='Staked Amount'
                                        statusValue={prettyNumber(accountStakedAmount)}
                                        ctaButtons={[
                                            accountStakedAmount > 0 && (
                                                <Button
                                                    classNames="btn btn-primary btn-sm mr-2"
                                                    onClick={() => system.showModal('unstake-iai', {accountStakedAmount: accountStakedAmount,
                                                    canUnstake: canUnstake, unStakeDate: compareDate})}
                                                >
                                                    <i className="ri-lock-unlock-line mr-2"/>Unstake iAI
                                                </Button>),
                                            <Button
                                                classNames="btn btn-primary btn-sm"
                                                onClick={() => system.showModal('stake-iai', {
                                                    minStakeAmount: minimumStakeAmount,
                                                    iaiBalance: iaiBalance
                                                })}
                                            >
                                                <i className="ri-lock-line mr-2"/>Stake iAI
                                            </Button>


                                        ]}
                                    />
                                    {/*<StepCard*/}
                                    {/*    title='Step 3'*/}
                                    {/*    text='Complete KYC'*/}
                                    {/*    status={account.kycStatus === 'COMPLETE'}*/}
                                    {/*    statusLabel='Status'*/}
                                    {/*    statusValue={account.kycStatus}*/}
                                    {/*    tooltipText=''*/}
                                    {/*    ctaButton={<Button classNames="btn btn-primary btn-sm"><i*/}
                                    {/*        className="ri-profile-line mr-2"/>Complete KYC</Button>}*/}
                                    {/*/>*/}
                                </div>
                            </TabPane>
                            <TabPane id="assets-tab">
                                <div className="row mb-5">
                                    {assets.map((a) => {
                                        return <ItemCard
                                            classNames="col-12 col-sm-6 col-md-4"
                                            collection={a.collection}
                                            title={a.title}
                                            tokenID={a.token_id}
                                            owner=""
                                            stockQty={a.totalSupply}
                                            currency={'iAI'}
                                            forSale={false}
                                            card={{id: 1, img: a.img}}
                                            listed={false}
                                            isAdmin={false}
                                        />
                                    })}
                                    {assets.length == 0 &&
                                         <>
                                            <p className="mb-3">No owned assets yet!</p>
                                            <Button classNames="btn btn-primary w-auto" onClick={() => { navigate('/auction-house'); }}>Start Bidding</Button>
                                        </>
                                    }
                                </div>
                            </TabPane>
                            <TabPane id="bids-tab">
                                <div className="col-12 col-md-6">
                                    {bids.map((b) => {
                                        return <BidListItem b={b} showNFT={true}/>
                                    })}
                                    {bids.length === 0 &&
                                        <>
                                        <p className="mb-3">No bids yet!</p> 
                                        <Button classNames="btn btn-primary w-auto" onClick={() => { navigate('/auction-house'); }}>Start Bidding</Button>
                                        </>
                                     }

                                </div>
                            </TabPane>
                            {/*<TabPane id="tabs-4">*/}
                            {/*    <div className="row mb-5">*/}
                            {/*        <ItemCard*/}
                            {/*            classNames="col-12 col-md-4"*/}
                            {/*            title="Abstract Art"*/}
                            {/*            owner="BLACK LEMON"*/}
                            {/*            stockQty={5}*/}
                            {/*            price={2000}*/}
                            {/*            currency={'iAI'}*/}
                            {/*            forSale={false}*/}
                            {/*            likes='1.2k'*/}
                            {/*            card={{id: 1, img: fineArt5}}*/}
                            {/*        />*/}
                            {/*    </div>*/}
                            {/*</TabPane>*/}
                            {/*<TabPane id="stakes-tab">*/}
                            {/*    <div className="col-12 col-md-5 mb-3">*/}
                            {/*        <StakeCard/>*/}
                            {/*    </div>*/}
                            {/*</TabPane>*/}
                            <TabPane id="minting-tab">
                                <Minting account={account} isAdmin={isAdmin} reloadData={() => {
                                    getCreatedNFTs();
                                }}/>
                            </TabPane>
                            <TabPane id="ahm-tab">
                                <div className="row mb-5">
                                    {createdNFts.length == 0 && <p>No minted NFTs yet!</p>}
                                    {createdNFts.map((nft) => {
                                        return <ItemCard
                                            classNames="col-12 col-sm-6 col-md-4"
                                            listingID={nft.listing?.list_id}
                                            collection={nft.collection}
                                            title={nft.title}
                                            tokenID={nft.token_id}
                                            stockQty={nft.totalSupply}
                                            price={2000}
                                            currency={'iAI'}
                                            forSale={false}
                                            likes='1.2k'
                                            card={{id: 1, img: nft.img}}
                                            listed={nft.listing}
                                            isAdmin={isAdmin}
                                            ownerWallet={ahOwner}
                                        />
                                    })}
                                </div>
                            </TabPane>
                            <TabPane id="tabs-6">
                                <div className="col-12 md-5 mb-3 row">
                                    {user?.artworks?.map(artwork =>
                                        <ArtworkCard
                                            classNames="col-12 col-md-4"
                                            title={artwork.title}
                                            image={artwork.url}
                                            userName={user?.username}
                                        />)
                                    }
                                </div>
                            </TabPane>
                            <TabPane id="nft-holograms-tab">
                                <div className="md-5 mb-3 row row-eq-height">
                                    <div className={'mb-3 col-md-6'}>
                                        <div className="submittedNFT px-3 py-4 text-center" style={{
                                            borderRadius: '20px',
                                            background: '#000',
                                            minHeight: 250,
                                            display: 'flex',
                                            flexDirection: 'column',
                                            cursor: 'pointer'
                                        }} onClick={() => {
                                            navigate('/sss#choosePlan');
                                        }}>
                                            <div className={'my-auto text-center'}><h1>+</h1> Click Here to submit new
                                                NFT
                                            </div>
                                        </div>
                                    </div>
                                    {user?.nfts?.map(nft =>
                                        <SubmittedNFT
                                            classNames="col-12 col-md-4"
                                            nft={nft}
                                            onUpdate={setUser}
                                        />)
                                    }
                                </div>
                            </TabPane>
                        </TabsContent>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Profile
