import React from "react";
import ShareBtn from "../atoms/share-btn";
import logo from '../../assets/img/logos/iai_logo.png'

function ItemDetailHeader({ cardName, collectionSize, owner }) {
  return (
    <>
      <h3>{cardName}</h3>
      <div className="d-flex justify-content-between">
        <div className="avatars space-x-5">
          <div className="media">
              <img src={logo} alt="Avatar" className="avatar avatar-sm" style={{background: 'none'}}/>
          </div>
          <div>
              <p className="avatars_name color_white">{owner}</p>
          </div>
        </div>
        <div className="space-x-10 d-flex align-items-center">
          <ShareBtn />
        </div>
      </div>
      {/*<div className="space-x-10 d-flex align-items-center mt-0">*/}
      {/*  <p className="text-nowrap"> 1 of {collectionSize}</p>*/}
      {/*</div>*/}
    </>
  );
}

export default ItemDetailHeader;
