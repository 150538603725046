import {BrowserRouter, Routes, Route} from "react-router-dom";
import Container from "./components/atoms/container";
import Activity from "./components/templates/activity";
import AuctionHouse from "./components/templates/aucitonHouse";
import EditProfile from "./components/templates/edit-profile";
import Home from "./components/templates/home";
import Minting from "./components/templates/minting";
import Profile from "./components/templates/profile";
import "../node_modules/slick-carousel/slick/slick.css";
import "../node_modules/slick-carousel/slick/slick-theme.css";
import AdminProfile from "./components/templates/admin-profile";
import AdminItemDetail from "./components/templates/details-admin";
import NftDrops from "./components/templates/NftDrops";
import Announcements from "./components/templates/Announcements";
import NFTStaking from "./components/templates/nft-staking";
import PrivacyPolicy from "./components/templates/privacy-policy";
import Terms from "./components/templates/terms";
import About from "./components/templates/about";
import Tokenomics from "./components/templates/tokenomics";
import Support from "./components/templates/support";
import NFTList from "./components/templates/NFTList";
import Article from "./components/templates/article";
import NFTDropDetail from "./components/templates/nft-drop-detail";
import ScrollToTop from "./components/atoms/scrollTop";
import SSS from "./components/templates/sss";
import {Helmet} from "react-helmet";


import TagManager from 'react-gtm-module';
import ItemDetail from "./components/templates/detail";
import config from "./config";
import {useEffect, useState} from "react";
import {useEthers} from "@usedapp/core";
import callAPI from "./api";
import browserSignature from 'browser-signature';
import {isAndroid, isIOS, isMobile, isWindows, isMacOs} from 'react-device-detect';
import Protected from "./components/ProtectedRoute";
import { UnsupportedChainIdError } from '@web3-react/core'
import {useSystem} from "./hooks/use-system";


const tagManagerArgs = {
    gtmId: 'GTM-NMB2WTS'
}

TagManager.initialize(tagManagerArgs)

function App() {
    const [signed, setSigned] = useState(false);
    const system = useSystem();
    const {account, library, deactivate, activate, chainId, error} = useEthers();
    const envChainId = config.chainId

    const requestSignature = async (callBack) => {
        const d = new Date();
        const messageToSign = 'I want to login on Inheritance Art at ' + d.toLocaleString();

        const signature = await library.getSigner().signMessage(messageToSign,);

        activateDevice(messageToSign, signature)
        setSigned(true);
    }

    useEffect(() => {
        if(error && error.name === 'UnsupportedChainIdError'){
            system.setMessage(`Please make sure your wallet is connected to the Ethereum ${config.networkName}.`)
        }else{
            system.setMessage('');
        }
    }, [error])

    const activateDevice = (message, signature) => {
        const browser_id = browserSignature();
        localStorage.setItem(config.constants.STORAGE.DEVICE_ID, browser_id);

        callAPI({
            message: message,
            signature: signature,
            wallet: account,
            device_id: browser_id,
            device_type: isAndroid ? 'ANDROID' : (isIOS ? 'IOS' : (isWindows ? 'Windows' : (isMacOs ? 'MAC' : 'UNKNOWN')))
        }, 'activateDevice').then((resp) => {
            if(resp.status){
                localStorage.setItem(config.constants.STORAGE.AUTH_TOKEN, resp.payload.auth_token);
                localStorage.setItem(config.constants.STORAGE.WALLET, account);
            }
            else
                deactivate();
        }).catch((err) => {
            alert(err);
            deactivate();
        });
    }


    useEffect(() => {
        const authTokenSaved = localStorage[config.constants.STORAGE.AUTH_TOKEN];
        const walletSaved = localStorage[config.constants.STORAGE.WALLET];

        const shouldSign = (!authTokenSaved && !signed) || (account && authTokenSaved && account != walletSaved)
        console.log('Should Sign: %s, Signed: %s', shouldSign, signed);
        if (shouldSign) {
            console.log('Requesting Signature');
            requestSignature();
        }
    }, [account]);

    useEffect(() => {
        if (chainId && window.ethereum) {
            window.ethereum.request({
                method: 'wallet_switchEthereumChain',
                params: [{ chainId: '0x'+envChainId }],
            });
        }
    }, [chainId, envChainId]);

    if(!account){
        if(localStorage[config.constants.STORAGE.AUTH_TOKEN] && localStorage[config.constants.STORAGE.CONNECTOR] == 'Injected')
            activate(config.Connectors.injected);
    }

    return (
        <BrowserRouter>
            <Helmet>
                <meta charSet="utf-8"/>
                <title>Inheritance Art</title>
                <meta name="twitter:card" content="summary_large_image"/>
                <meta name="twitter:site" content="@inheritanceArt"/>
                <meta name="twitter:url" content={window.location.href}/>
                <meta name="twitter:title" content="Inheritance Art"/>
                <meta name="twitter:description" content=""/>
                <meta name="twitter:image" content="https://cdn.inheritance-art.com/share/iai_banner.jpeg"/>
                <meta name="twitter:image:alt" content="Inheritance Art"/>
                <meta property="og:title" content="Inheritance Art"/>
                <meta property="og:description" content=""/>
                <meta property="og:image:secure_url" content="https://cdn.inheritance-art.com/share/iai_banner.jpeg"/>
                <meta property="og:url" content={window.location.href}/>
                <meta name="description" content="Inheritance Art"/>
                <meta property="og:type" content="website"/>
                <meta name="author" content="Inheritance Art"/>
            </Helmet>
            <ScrollToTop>
                <Routes>
                    <Route path="/" element={<Container/>}>
                        <Route index element={<Home/>}/>
                        <Route path="sss" element={<SSS/>}/>
                        <Route path="auction-house" element={<AuctionHouse/>}/>
                        <Route path="auction-house/collection/:collection/:tokenId" element={<ItemDetail/>}/>
                        <Route path="activity" element={<Activity/>}/>

                        <Route path="admin-detail" element={<AdminItemDetail/>}/>
                        <Route path="details/:slug" element={<NFTDropDetail/>}/>
                        <Route path="minting" element={<Minting/>}/>

                        <Route path="profile" element={<Protected account={account}><Profile/></Protected>}/>
                        <Route path="edit-profile" element={<Protected account={account}><EditProfile/></Protected>}/>

                        {/* <Route path="management" element={<ActivityManagement />} /> */}
                        {/*<Route path="nft-drops" element={<NftDrops/>}/>*/}
                        <Route path="nft-list" element={<NFTList/>}/>
                        <Route path="news/:category" element={<Announcements/>}/>
                        <Route path="news" element={<Announcements/>}/>
                        <Route path="article/:slug" element={<Article/>}/>
                        <Route path="nft-staking" element={<NFTStaking/>}/>
                        <Route path="privacy" element={<PrivacyPolicy/>}/>
                        <Route path="terms" element={<Terms/>}/>
                        <Route path="about" element={<About/>}/>
                        <Route path="token-integration" element={<Tokenomics/>}/>
                        <Route path="support" element={<Support/>}/>
                        {/* <Route path="*" element={<></>} /> */}
                    </Route>
                </Routes>
            </ScrollToTop>
        </BrowserRouter>
    );
}

export default App;
