import React, {useContext, useEffect, useState} from 'react'
import SectionHeader from '../molecules/sectionHeader'
import HeroLG from '../organisms/hero-large'
import spatialDisplay from '../../assets/img/spatial_display.png'
import preview_1 from '../../assets/img/preview_1.png'
import preview_2 from '../../assets/img/preview_2.png'
import preview_3 from '../../assets/img/preview_3.png'
import elipse_right from '../../assets/img/bg/elipse_right.png'
import elipse_left from '../../assets/img/bg/elipse_left.png'
import Button from '../atoms/button'
import {useSystem} from '../../hooks/use-system'
import Cube from '../atoms/icons/Cube'
import ArrowRight from '../atoms/icons/ArrowRight'
import vector_top from '../../assets/img/vector_soon_1.png'
import vector_bottom from '../../assets/img/vector_soon_2.png'
import art_4 from '../../assets/img/art_4.png'
// import FaqAccordion from '../molecules/faqAccordion'
// import RequirementsAccordion from '../molecules/RequirementsAccordion'
import FindMore from '../molecules/FindMore'
import CommunityLinks from '../molecules/community-links'
import NewsCard from '../molecules/NewsCard'
import fineArt4 from '../../assets/img/items/fine_art_4.jpg'
import fineArt5 from '../../assets/img/items/fine_art_5.jpg'
import fineArt6 from '../../assets/img/items/fine_art_6.jpg'
import left from '../../assets/img/left_par.png'
import right from '../../assets/img/right_par.png'
import iai_half_logo from '../../assets/img/bg/iai_half.png'
import community from '../../assets/img/icons/community.png'
import token from '../../assets/img/icons/token.png'
import services from '../../assets/img/icons/services.png'
import tech from '../../assets/img/icons/tech.png'
import star from '../../assets/img/icons/star.png'
import Slide1 from '../organisms/slides/slide_1'
import Slide2 from '../organisms/slides/slide_2'
import Slide3 from '../organisms/slides/slide_3'
import Slider from 'react-slick'
import axios from 'axios'
import LatestNews from '../organisms/latest-news';
import {useNavigate} from "react-router";
import { Link } from 'react-router-dom'


function Home() {

    const client = axios.create({baseURL: "https://dapps-apis.inheritance-art.com/api/articles/get-articles"});

    const [articles, setArticles] = useState([]);

    const navigate = useNavigate();

    useEffect(() => {
        async function getPost() {
            const response = await client.get(`?page=1&page_length=3`);
            if (response.data.status === 'SUCCESS') {
                setArticles([...articles, ...response.data.payload.articles]);
            } else if (response.data.message === 'NO_ARTICLES_FOUND') {
                return;
            }
        }

        getPost();
    }, []);

    const sliderSettings = {
        customPaging: function (i) {
            return (
                <div className="number-container">
                    <span className="number-back">0{i + 1}</span>
                    <span className="page-num">0{i + 1}</span>
                </div>
            );
        },
        dots: true,
        dotsClass: "slider-pagination",
        infinite: false,
        autoplay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };


    return (
        <>
            <Slider {...sliderSettings}>
                <Slide1/>
                <Slide2/>
                <Slide3/>
            </Slider>

            <div className="my-5"></div>
            <div className="section">
                <div className="d-flex mb-5 row">
                    <div id="intro-text" className="col-12 mb-3 d-flex flex-column justify-content-center">
                        <img src={iai_half_logo} className="d-none d-md-block"
                             style={{position: 'absolute', left: '0', top: '0', height: '700px', width: 'auto'}}></img>
                        <SectionHeader text="inheritance Art"/>
                        <div className='col-12 col-md-9 mb-5'>
                            <p className="text-light mb-4" style={{fontSize: '25px', fontWeight: '100'}}>
                                inheritance Art is an artificial intelligence and animation laboratory focused on
                                creating hyper realistic and volumetric intelligent content.
                            </p>
                            <p className="text-light" style={{fontSize: '25px', fontWeight: '100'}}> Over the next three
                                years, inheritance Art aims to become the biggest NFT / <span className="text-primary">intelligent NFT producer</span> and
                                production studio for mixed reality and hyper realistic productions.</p>
                        </div>
                        <div className="">
                            <Button onClick={() => { navigate('/about'); }}>Learn More</Button>
                        </div>
                    </div>

                </div>
            </div>
            <div className="px-2 px-md-5 section">
                <div className="d-flex justify-content-center my-5 row row-eq-height">
                    <div className='col-12 col-md-2 mb-3'>
                        <div className="card service-card p-3">
                            <img src={tech}/>
                            <h6 className="text-primary mb-2">Sophisticated Technology</h6>
                            <p className='text-light'>Backed by Artificial Intelligence</p>
                        </div>
                    </div>
                    <div className='col-12 col-md-2 mb-3'>
                        <div className="card service-card p-3">
                            <img src={token}/>
                            <h6 className="text-primary mb-2">inheritance Art Token</h6>
                            <p className='text-light'>Available on BSC and ETH</p>
                        </div>
                    </div>
                    <div className='col-12 col-md-2 mb-3'>
                        <div className="card service-card p-3">
                            <img src={services}/>
                            <h6 className="text-primary mb-2">Unique Services</h6>
                            <p className='text-light'>Spatial Spawns Service for hyper resolution NFT Holograms</p>
                        </div>
                    </div>
                    <div className='col-12 col-md-2 mb-3'>
                        <div className="card service-card p-3">
                            <img src={community}/>
                            <h6 className="text-primary mb-2">Global Community</h6>
                            <p className='text-light'>Powered by solid networks across Twitter, Discord, & Instagram</p>
                        </div>
                    </div>
                    <div className='col-12 col-md-2 mb-3'>
                        <div className="card service-card p-3">
                            <img src={star}/>
                            <h6 className="text-primary mb-2">Creative Team</h6>
                            <p className='text-light'>Collaborations with Artists from all over the world</p>
                        </div>
                    </div>
                </div>
                <div className="d-flex my-5 py-5 row">
                    <SectionHeader text="Spatial Spawns Service" backdrop="SPATIAL SPAWNS SERVICE"/>
                    <div className='col-12 col-md-7 mb-5'>
                        <p className="text-light" style={{fontSize: '25px'}}>Are you a true NFT Lover? Imagine you’re
                            the proud owner of some amazing NFTs. If there was a way to view your NFTs as <span
                                className="text-primary">hyper realistic holograms</span> without the need for special
                            glasses or HMDs and display them anywhere you want, wouldn’t you want to take that
                            opportunity?</p>
                        <p className="text-light" style={{fontSize: '25px'}}>The Spatial Spawns service by inheritance
                            Art will make it possible!</p>
                    </div>
                </div>
                <div className="d-flex mb-5 row">
                    {/* <div className="col-12 col-md-7">
                        <RequirementsAccordion/>
                    </div> */}
                    <SectionHeader text="Spatial Spawns Service Plans" backdrop="Service Plans" justify="end"/>
                    <div className="col-12 col-md-6 mb-3 mb-md-0">
                        <img src={spatialDisplay} className="w-100"></img>
                    </div>
                    <div className="col-12 col-md-6">
                        <div className="row d-flex justify-content-evenly">
                            <div className="col-12 col-md-6 mb-5 mb-md-0">
                                <div className="card plan-card secondary">
                                    <h3 className="card-header secondary text-center p-4">ONE TIME FEE</h3>
                                    <div className="card-body">
                                        <p className="card-text text-light py-2">For NFT owners who want their NFT
                                            spawned
                                            into Spatial Reality without the yearly service subscription.</p>
                                        <hr style={{backgroundColor: '#177A88'}}></hr>
                                        <ul className="plan-list">
                                            <li className="mb-3">One of your favorite NFTs spawned into Spatial
                                                Reality
                                            </li>
                                            <li className="mb-3">Unique 8K resolution hyper realistic NFT Hologram</li>
                                            <li className="mb-3">NFT Hologram price <span className="text-primary">8,500 USDC</span>
                                            </li>
                                        </ul>
                                        {/* <div className='d-flex justify-content-center'><Button
                                            onClick={() => {
                                                payBtnClick('oneTime');
                                            }}>BUY NOW &nbsp; <ArrowRight/></Button>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 col-md-6 mb-5 mb-md-0">
                                <div className="card plan-card">
                                    <h3 className="card-header text-center p-4">YEARLY SUBSCRIPTION</h3>
                                    <div className="card-body gradient-bg">
                                        <p className="card-text text-light py-2">For NFT holders who want to get
                                            multiple
                                            NFT Holograms with all the benefits of the yearly Spatial Spawns
                                            subscription.</p>
                                        <hr style={{backgroundColor: '#177A88'}}></hr>
                                        <ul className="plan-list">
                                            <li className="mb-3">One of your favorite NFTs spawned into Spatial
                                                Reality
                                            </li>
                                            <li className="mb-3">Unique 8K resolution hyper realistic NFT Hologram</li>
                                            <li className="mb-3">NFT Hologram price in iAI Token equals <span
                                                className="text-primary">5,000 USDC</span></li>
                                            <li className="mb-3">For every extra NFT Hologram ordered, you get it at
                                                discounted price of <span className="text-primary">3,500 USDC </span>
                                            </li>
                                            <li className="mb-3">Priority access to all Inheritance Art projects</li>
                                        </ul>
                                        {/* <div className='d-flex justify-content-center'><Button onClick={() => {
                                            payBtnClick('subscription');
                                        }}>SUBSCRIBE NOW &nbsp; <ArrowRight/></Button></div> */}
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="d-flex justify-content-center mt-5">
                                        <Link to="/sss"><Button>Learn More</Button></Link>
                        </div>
                    </div>
                </div>
            </div>
            <div className="section absolute-section" style={{position: 'relative'}}>
                <img src={vector_top} className="vector-top"/>
                <img src={art_4} style={{position: 'absolute', right: 0, bottom: '-120px', zIndex: 1}}/>
                <div className="text-center coming-soon-section">
                    <div className="text d-flex flex-column justify-content-center">
                        <h1 className="mb-2">inheritance Auction House</h1>
                        <h1 className="text-primary mb-5">COMING SOON</h1>
                        <div className="home-paragraph mb-5">
                            <img src={left} className="left"/>
                            <p>inheritance Art’s <span className="text-primary">vision</span> is to grant everyone,
                                everywhere the ability to enjoy experience and obtain fine art, delivered through
                                blockchain NFTs. </p>
                            <img src={right} className="right"/>
                            <br></br>
                            <p className="footer">- Vincent Peters, Founder -</p>
                        </div>
                    </div>
                </div>
                <img src={vector_bottom} className="vector-bottom"/>
            </div>
            <div className="container section">
                <SectionHeader text="Latest News" backdrop="LATEST NEWS"/>
                <LatestNews/>
            </div>
            <div className="container mb-5">
                <CommunityLinks titleBackdrop />
            </div>

        </>
    )
}

export default Home