import React from "react";
import {useSystem} from "../../../hooks/use-system";
import Message from "../../molecules/message";
import ChooseWallet from "../../molecules/modals/chooose-wallet";
import BurnNFTModal from "./burn-confirm";
import CancelAuctionModal from "./cancelAuction";
// import MessageModal from "./message-modal/message-modal";
import PlaceBidModal from "./placeBidModal";
import PutOnAuctionModal from "./putOnAuction";
import StakeIAIModal from "./stakeIAI";
import UnStakeIAIModal from "./unStakeIAI";
import ServiceSubscription from "./serviceSubscription";
import {useWeb3React} from "@web3-react/core";
import CreateCollection from "../../molecules/modals/CreateCollection";

const SystemModals = () => {
    const {modal, processStages, message, messageType} = useSystem();

    const formatter = new Intl.NumberFormat('us-US', {maximumSignificantDigits: 8});

//   if (processStages?.length > 0) {
//     return <ProcessModal />;
//   }
    if (modal.name === 'choose-wallet') {

        return <ChooseWallet/>
    }
    if (modal.name === 'create-collection') {
        return <CreateCollection onSuccess={modal.data.onSuccess}/>
    }
    if (message) {
        return <Message type={messageType}>{message}</Message>;
    }

    if (modal.name === 'subscribe-sss' || modal.name === 'pay-sss') {

        const eligible = modal.data.balance >= modal.data.payAmount;

        const curStage = null;

        const successMessage = modal.name == "subscribe-sss" ? 'Congrats! You\'re now successfully subscribed to our Spatial Spawned service!' : 'Congrats! You have now successfully purchased our Spatial Spawned service!';

        const descriptionText = modal.name == "subscribe-sss" ? `Amount to be deducted from your wallet for the yearly subscription is <b className="white-text">${formatter.format(modal.data.payAmount)} ${modal.data.payCurrency}</b>`:
            `Amount to be deducted from your wallet for the one time hologram design purchase is <b className="white-text">${formatter.format(modal.data.payAmount)} ${modal.data.payCurrency}</b>`;

        return <ServiceSubscription
            eligible={eligible}
            subscription={modal.data.subscription}
            subscriber={modal.data.subscriber}
            receiverWallet={modal.data.receiverWallet}
            stage={curStage}
            payCurrency={modal.data.payCurrency}
            payAmount={modal.data.payAmount}
            btnText={!eligible ? 'Not Enough Balance' : modal.name === 'subscribe-sss' ? 'SUBSCRIBE NOW' : 'BUY NOW'}
            title={`Balance in your wallet: <b className="white-text">${formatter.format(modal.data.balance)} ${modal.data.payCurrency}</b>`}
            description={descriptionText}
            disclaimer={`Disclamer note: In case your NFT is not approved by the inheritance Art team we
                will ask you to share a new NFT link.`}
            successMessage={successMessage}
            btnClick={() => {

            }}
        />;
    }

    if (modal.name === 'place-bid') {
        return <PlaceBidModal listingInfo={modal.data.listingInfo} callBack={modal.data.callback}/>;
    }
    if (modal.name === 'stake-iai') {
        return <StakeIAIModal minStakeAmount={modal.data.minStakeAmount} iaiBalance={modal.data.iaiBalance}/>;
    }
    if (modal.name === 'unstake-iai') {
        return <UnStakeIAIModal accountStakedAmount={modal.data.accountStakedAmount} canUnstake={modal.data.canUnstake} unstakeDate={modal.data.unStakeDate}/>;
    }
    if (modal.name === 'put-on-auction') {
        return <PutOnAuctionModal collection={modal.data.collection} tokenId={modal.data.tokenId} ownerWallet={modal.data.ownerWallet}/>;
    }
    if (modal.name === 'cancel-auction') {
        return <CancelAuctionModal listingInfo={modal.data.listingInfo}/>;
    }
    if (modal.name === 'burn') {
        return <BurnNFTModal/>;
    }

    return null;
};

export default SystemModals;
