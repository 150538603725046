import React from "react";
import CountdownTimer from "./countdown";
import {prettyNumber} from "../../utils/string";

function ItemsDetailNumbers({
                                listingInfo
                            }) {
    return (
        <div className="numbers">
            <div className="row">
                <div className="col-6 col-md-4 order-md-2">
                    <div className="space-y-5">
                        <p className="color_text">Current Bid</p>
                        <h4>{prettyNumber(listingInfo?.price)}<span className="txt_sm color_text"> {listingInfo?.in_iAI ? 'iAI': 'WETH'}</span></h4>
                    </div>
                </div>
                <div className="col-6 col-md-6">
                    <div className="space-y-5">
                        <p className="color_text">Countdown</p>
                        <div className="d-flex countdown_item align-items-center">
                            {listingInfo.highestBidder === null ? (
                                <p style={{lineHeight: '100%'}}><small><small><small>Will start once <br/>1st bid is placed</small></small></small></p>
                            ): (
                                <CountdownTimer date={new Date(listingInfo.endTime*1000)}/>
                            )}

                        </div>

                    </div>
                </div>
            </div>
        </div>
    );
}

export default ItemsDetailNumbers;
