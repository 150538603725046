import React from 'react'
import ItemCard from '../molecules/ItemCard'
import SectionHeader from '../molecules/sectionHeader'
import fineArt5 from '../../assets/img/items/r.png'
import nft_1 from '../../assets/img/nfts/1.jpg'
import nft_2 from '../../assets/img/nfts/2.jpg'
import nft_3 from '../../assets/img/nfts/3.jpg'
import nft_4 from '../../assets/img/nfts/4.jpg'
import nft_5 from '../../assets/img/nfts/5.jpg'
import nft_6 from '../../assets/img/nfts/6.jpg'
import nft_7 from '../../assets/img/nfts/7.jpg'
import rw_1 from '../../assets/img/rewards/1.jpg'
import rw_2 from '../../assets/img/rewards/2.jpg'
import rw_3 from '../../assets/img/rewards/3.jpg'
import rw_4 from '../../assets/img/rewards/4.jpg'
import rw_5 from '../../assets/img/rewards/zenith.gif'
import { Link } from 'react-router-dom'
import Button from '../atoms/button'
import NewsCard from '../molecules/NewsCard'
import RewardCard from '../molecules/RewardCard'
import PageTitle from '../atoms/pageTitle'
import PageBanner from '../molecules/page-banner'
import Accordion from '../atoms/Accordion'
import AccordionItem from '../atoms/AccordionItem'
import LatestNews from '../organisms/latest-news'
import { isMobile } from 'react-device-detect'
import nftDropsBanner from '../../assets/img/banners/drops.jpg'
import nftDropsBannerMobile from '../../assets/img/banners/mobile/drops.jpg'

function NftDrops() {
	const newsCards = [
		{img: fineArt5,},
		{img: fineArt5,},
		{img: fineArt5,},
	]
	const nftCards = [
		{img: nft_1, tier: '1', title: 'Lilac Crown', slug:'lilac-crown'},
		{img: nft_2, tier: '2', title: 'Indigo Third Eye', slug:'indigo-third-eye'},
		{img: nft_3, tier: '3', title: 'Sapphire Throat', slug: 'sapphire-throat'},
		{img: nft_4, tier: '4', title: 'Jade Heart', slug: 'jade-heart'},
		{img: nft_5, tier: '5', title: 'Solar Plexus', slug: 'solar-plexus'},
		{img: nft_6, tier: '6', title: 'Coral Saclar', slug: 'coral-sacral'},
		{img: nft_7, tier: '7', title: 'Ruby Root', slug: 'ruby-root'},
	]
	const rewardCards = [
		{img: rw_1, title: 'Zenith', slug: 'zenith-1'},
		{img: rw_2, title: 'Zenith', slug: 'zenith-2'},
		{img: rw_3, title: 'Zenith', slug: 'zenith-3'},
		{img: rw_4, title: 'Zenith', slug: 'zenith-4'},
		{img: rw_5, title: 'Zenith', slug: 'zenith-5'},
		
	]
  return (
	  <>
	<PageBanner img={ isMobile ? nftDropsBannerMobile : nftDropsBanner }/>
	<PageTitle text1={"NFT Drops"} classNames="bg-dark" />
	<div className="col-10 col-md-4 mx-auto text-center mb-5">
		<p>Featuring all inheritance Art NFT Collections</p>
	</div>
    <div className='container'>
	<SectionHeader text="SEED NFTs"/>
				<div className="d-flex row">
					{nftCards.map(
						card => <RewardCard 
								id = {1}
								image = {card.img}
								title = {card.title}
								tier = {card.tier}
								classNames="col-12 col-md-3"
								slug={card.slug}
							/>
					)}
				</div>
				<div className="d-flex flex justify-content-center">
					{/* <Link to="/nft-list">
						<Button classNames="btn btn-primary">View All Seed NFTs</Button>
					</Link> */}
				</div>
	<div className='my-5'></div>
	<SectionHeader text="NFT Rewards"/>
				<div className="d-flex row">
					{rewardCards.map(
						card => <RewardCard 
								id = {1}
								image = {card.img}
								title = {card.title}
								tier = {card.tier}
								classNames="col-12 col-md-3"
								slug={card.slug}
							/>
					)}
				</div>
				<div className="d-flex flex justify-content-center">
					{/* <Link to="/nft-list">
						<Button classNames="btn btn-primary">View All NFT Rewards</Button>
					</Link> */}
				</div>
	<SectionHeader text="Latest News"/>
	<LatestNews/>
    </div>
</>
  )
}

export default NftDrops