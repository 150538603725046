import { ChainId, Rinkeby, Mainnet } from '@usedapp/core';

const stage = process.env.REACT_APP_ENV;

console.log(`Network: ${stage === 'staging' ? 'RINKEBY' : 'MAINNET'}`);

const DappConfig = {
    readOnlyChainId: stage === 'staging' ? ChainId.Rinkeby : ChainId.Mainnet,
    readOnlyUrls: {
      [ChainId.Mainnet]: `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`,
      [ChainId.Rinkeby]: `https://rinkeby.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`
    },
    pollingInterval: 1000,
    networks: [stage === 'staging' ? Rinkeby : Mainnet],
    notifications: {
      checkInterval: 100,
      expirationPeriod: 10000,
    },
    autoConnect: true
}

export default DappConfig;