import React from "react";
import Countdown from "react-countdown";

function CountdownTimer({ date, small }) {
  const renderer = ({ days, hours, minutes, seconds, completed }) => {
    return completed ? (
      "Finished"
    ) : (
      <div
        className={`d-flex justify-content-center align-items-center space-x-5 txt_${
          small ? "sm" : "lg"
        } _bold`}
      >
        {(days && +days > 0) ? (
          <div className="item">
            <div className="number days text_bold">
              {`${days}`}
              <span>d</span>
            </div>
          </div>
        ) : null}
        {(days > 0) && <div className="dots">:</div>}
        <div className="item">
          <div className="number hours text_bold">
            {hours}
            <span>h</span>
          </div>
        </div>
        <div className="dots">:</div>
        <div className="item">
          <div className="number minutes text_bold">
            {minutes}
            <span>m</span>
          </div>
        </div>
        <div className="dots">:</div>
        <div className="item">
          {" "}
          <div className="number seconds text_bold">
            {seconds}
            <span>s</span>
          </div>{" "}
        </div>
      </div>
    );
  };
  return <Countdown date={date} renderer={renderer} zeroPadTime={2} />;
}

export default CountdownTimer;
