import React from 'react'

function AboutCard({img, name, title, text}) {
  return (
	<div className="col-12 col-md-4 mb-10">
		<div className="creator_item creator_card space-y-10 is_big h-100"  style={{position: 'relative'}}>
			<div className="avatars flex-column space-y-10 p-3">
				<div className="media">
					<img src={img} alt="Avatar" className="avatar avatar-md" />
				</div>
				<div className="text-center">
					<a href="Profile.html">
						<p className="avatars_name large text-light">{name}</p>
					</a>
					<span className="sales color_text">{title}</span>
				</div>
				<div className="text-left">
						<p className="text-light">{text}</p>
				</div>
				<div className="socials d-flex justify-content-center" style={{position: 'absolute', bottom:'10px', left:'50%', transform: 'translateX(-50%)'}}>
					<a href="#"><i className="ri-linkedin-line mr-3 ri-2x"></i></a>
					<a href="#"><i className="ri-twitter-line mr-3 ri-2x"></i></a>
					<a href="#"><i className="ri-instagram-line ri-2x"></i></a>
				</div>
			</div>
		</div>
	</div>
  )
}

export default AboutCard