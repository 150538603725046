import React, {useEffect, useState} from 'react'
import Modal from '../../atoms/modal'
import {BalanceOf, UseContractMethod} from "../../../hooks/use-dapp";
import {useContract} from "../../../hooks/use-contract";
import Button from "../../atoms/button";
import web3 from "web3";
import {useEthers, useGasPrice} from "@usedapp/core";
import addresses from "../../../config/addresses";
import callAPI from "../../../api";
import {prettyNumber} from "../../../utils/string";

function PlaceBidModal({listingInfo, callBack}) {

    const contract = useContract();

    const {error, account} = useEthers();

    const currency = listingInfo.in_iAI ? 'iAI' : 'WETH';

    const iaiBalance = web3.utils.fromWei(BalanceOf(contract?.iai, account));
    const weithBalance = web3.utils.fromWei(BalanceOf(contract?.weth, account));

    const newBid = web3.utils.fromWei((listingInfo.rawPrice * 110/100).toString());
    const minPrice = listingInfo.highestBidder ? newBid.toString() : listingInfo.price;

    const [canBid, setCanBid] = useState(true);
    const [bidData, setData] = useState({});
    const [initialPrice, setInitialPrice] = useState(minPrice);
    const [bidIsBeingPlaced, setBidIsBeingPlaced] = useState(false);
    const [bidIsPlaced, setBidIsPlaced] = useState(false);
    const [bidMessage, setBidMessage] = useState(null);
    const [approvalGiven, setApprovalGiven] = useState(false);

    const {
        state,
        send,
        events
    } = UseContractMethod(contract?.auctionHouse, "bid");

    const {
        state: stateApproval,
        send: sendApproval,
        events: eventsApproval
    } = UseContractMethod(listingInfo?.in_iAI ? contract?.iai : contract?.weth, "approve");

    useEffect(() => {

        if (state?.status == 'Mining') {
            setBidMessage(`🔐 Bid Transaction signed! Placing the bid...`)
        } else if (state?.status == 'Success' && !bidIsPlaced) {
            setBidIsPlaced(true);
            callAPI({
                list_id: listingInfo.list_id,
                bid_price: bidData && bidData.price ? bidData.price : minPrice,
                trx_hash: state.transaction.hash
            }, 'placeBid').then((data) => {
                callBack();
            });;

        }

    }, [state, events])

    useEffect(() => {


        if (stateApproval?.status == 'Mining') {
            setBidMessage(`🔐 Transaction signed! Executing the approval on ${prettyNumber(bidData && bidData.price ? bidData.price : minPrice)} ${currency}...`)
        } else if (stateApproval?.status == 'Success' && !approvalGiven) {
            setApprovalGiven(true)
            setBidMessage(`Approval Given! Please sign the bid transaction...`);

            send(listingInfo.list_id, web3.utils.toWei(bidData && bidData.price ? bidData.price.toString() : minPrice.toString()));
        }

    }, [error, stateApproval, eventsApproval])

    useEffect( () => {
        const canBid = currency == 'iAI' ? iaiBalance >= minPrice : weithBalance >= minPrice;
        setCanBid(canBid);
    }, [iaiBalance, weithBalance])

    const setBidDataAttr = (attr, value) => {
        bidData[attr] = value;
        setData(bidData);
    }


    return (
        <Modal title="Place Bid">
            {bidIsPlaced ? (
                <>
                    <p>Your Bid has been placed successfully!</p>
                </>
            ) : (
                <>
                    <p>You must bid at least <span className="color_white">{prettyNumber(minPrice)} {currency}</span></p>
                    <input type="text" className="form-control" placeholder={`00.00 ${currency}`}
                           value={prettyNumber(initialPrice)}
                           onChange={(e) => {
                               setBidDataAttr('price', e.target.value);
                               setInitialPrice(e.target.value);
                           }}/>
                    {bidIsBeingPlaced ? (
                        <div className="d-flex">
                        {state.status==='Mining' && <i className="ri-donut-chart-fill md spin mr-2 text-primary"></i>}
                        <p className='text-light' style={{fontWeight: 'bold'}}>{bidMessage}</p>
                        </div>
                    ) : (
                        <Button
                            disabled={!canBid}
                            classNames="btn btn-primary btn-lg mx-auto"
                            onClick={() => {
                                sendApproval(addresses.auctionHouse, web3.utils.toWei(bidData && bidData.price ? bidData.price.toString() : minPrice.toString()));
                                setBidIsBeingPlaced(true);
                                setBidMessage(`Please sign the transaction to approve on ${prettyNumber(bidData && bidData.price ? bidData.price : minPrice)} ${currency}...`)
                            }}
                        >
                            {canBid ? <span>Place a Bid</span> : <span>Not enough balance!</span>}
                        </Button>
                    )}
                </>
            )}


        </Modal>
    )
}

export default PlaceBidModal