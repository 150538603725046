import React, {useEffect, useState} from 'react'
import InputRow from '../molecules/input-row'
import Input from '../atoms/input'
import Button from '../atoms/button'
import Dropzone from '../molecules/dropzone'

import {UseContractMethod} from "../../hooks/use-dapp";
import {useEthers, useGasPrice} from "@usedapp/core";
import callAPI from "../../api";
import {useSystem} from "../../hooks/use-system";
import config from "../../config";
import {Contract} from "@ethersproject/contracts";
import {useInterface} from "../../hooks/use-interface";
import web3 from "web3";
import createCollection from "../molecules/modals/CreateCollection";

function Minting({account, isAdmin, reloadData}) {

    const system = useSystem();
    const intrf = useInterface();

    const [isERC721, setERC721] = useState(null)
    const [minted, setMinted] = useState(false)
    const [nftData, setNFTData] = useState({
        supply: 1
    })
    const [trxMessage, setTrxMessage] = useState('Create Now');
    const [isLoading, setIsLoading] = useState(false);

    const [loadedFile, setLoadedFile] = useState(null);

    const [collections, setCollections] = useState([])
    const [collectionsDict, setCollectionsDict] = useState([])
    const [curContract, setContract] = useState([])

    const {
        state, send, events
    } = UseContractMethod(curContract, "mint");

    const getCollections = () => {
        callAPI({}, 'getCollections').then((data) => {
            setCollections(data.payload.collections);
            data.payload.collections.forEach((c) => {
                collectionsDict[c.address] = c;
            })
            setCollectionsDict(collectionsDict);
        });
    }

    useEffect(() => {
        if (collections.length == 0)
            getCollections();
    }, [])

    useEffect(() => {
        setNFTDataAttr('media_file', loadedFile);

    }, [loadedFile])

    const setNFTDataAttr = (attr, value) => {
        nftData[attr] = value;
        setNFTData(nftData);
    }

    const mintNFT = () => {
        if (curContract) {
            setIsLoading(true);
            setTrxMessage('Please sign the transaction...');
            if (isERC721)
                send(1, nftData.artist).then( res => {
			if(res.status===1){

				setERC721(null)
				setMinted(false)
				setNFTData({
					supply: 1
				})
				setTrxMessage('Create Now');
				setIsLoading(false);

				setLoadedFile(null);

				setContract([]);
				document.querySelector('input').forEach(el=>el.value='')
			}
		});
            else
                send(nftData.supply, nftData.artist, [])
        } else
            alert('Choose a collection first!')
    }

    const chooseCollection = (e, c_address) => {

        const c = collectionsDict[c_address];

        setNFTDataAttr('collection', c.address);
        let ct;

        if (c.type === config.constants.CONTRACT_TYPE.ERC721) {
            ct = new Contract(c.address, intrf.erc721);
            setContract(ct);
            setERC721(true);
        } else if (c.type === config.constants.CONTRACT_TYPE.ERC1155) {
            ct = new Contract(c.address, intrf.erc1155);
            setContract(ct);
            setERC721(false);
        }
    };

    const createNFT = () => {
        callAPI(nftData, 'createNFT').then((data) => {
            if (data.status === 'SUCCESS') {
                setTrxMessage('NFT Minted and Synced successfully!');
                reloadData();
            }
        });
    }

    useEffect(() => {

        if (state.status === 'Exception') {
            setIsLoading(false);
        } else if (state.status === 'Mining') {
            setTrxMessage('🔐 Transaction signed! Minting...');
        } else if (state.status === 'Success' && !minted) {
            setMinted(true)
            setTrxMessage('NFT Minted! Syncing with backend...');
            const tokenId = isERC721 ? state.receipt.events[0].args.tokenId.toNumber() : state.receipt.events[0].args.id.toNumber();
            setNFTDataAttr('token_id', tokenId);
            createNFT();
        }
    }, [account, state, events]);


    return (
        <div className="d-flex justify-content-center upload p-5">
            <div className="col-12">
                <div className="row fixed_price">
                    <div className="col-lg-12">
                        <h2 className="title">CREATE NEW COLLECTIBLE</h2>
                    </div>
                </div>
                <Dropzone onFileLoad={setLoadedFile}/>
                <h6 className="card_title">Upload file<span className="star">*</span>
                </h6>
                <p className="text-muted mb-3">Image, Video or Audio</p>
                <form id="minting-form" className="">
                    <InputRow title="Title" description="Name your collectible">
                        <Input disabled={isLoading} onChange={(e) => {
                            setNFTDataAttr('title', e.target.value)
                        }}
                               placeholder="e.g. 'inheritance Art NFT" required/> </InputRow>
                    <InputRow title="Description"
                              description="Provide a detailed description of your collectible. It will be included on item’s detail pade underneath its image."
                              required>
                        <Input disabled={isLoading} onChange={(e) => {
                            setNFTDataAttr('description', e.target.value)
                        }} placeholder="e.g. ‘inheritance Art NFt is lorem ipsum dolor sit amet...."/>
                    </InputRow>
                    <InputRow title="External Link"
                              description="This link will be included on the item’s detail page for users to learn more about your item."
                              required>
                        <Input onChange={(e) => {
                            setNFTDataAttr('external_link', e.target.value)
                        }} disabled={isLoading} placeholder="e.g. https://yoursite.com/item/123"/>
                    </InputRow>
                    <InputRow title="Unlockable content"
                              description="This link will be included on the item’s detail page for users access unlockable content."
                              required>
                        <Input onChange={(e) => {
                            setNFTDataAttr('unlockable_content', e.target.value)
                        }} disabled={isLoading} placeholder="e.g. https://yoursite.com/item/123"/>
                    </InputRow>

                    <InputRow title="Collection" description="This is a collection where your item will appear."
                              required>
                        <div className="d-flex flex-column flex-md-row">
                            <Button disabled={isLoading} href=""
                                    classNames="choose_collection mb-10 mb-md-0 mr-0 mr-md-3" onClick={() => {
                                system.showModal('create-collection', {
                                    onSuccess: () => {
                                        getCollections();
                                    }
                                })
                            }}>
                                <div className="icon"><i className="ri-add-line"></i></div>
                                New collection
                            </Button>
                            <div className={'col-sm-6'}>
                                <select disabled={isLoading} className="form-control" name={'chosenCollection'}
                                        onChange={(e) => {
                                            chooseCollection(e, e.target.value)
                                        }}>
                                    <option value={''}>Please Choose</option>
                                    {collections.map((c) => {
                                        return <option value={c.address}>{c.name} ({c.type}) - {c.royalties}%</option>
                                    })}
                                </select>
                            </div>
                            {/*{collections.map((c) => {*/}
                            {/*    const cl = "choose_collection mr-0 mr-md-3";*/}
                            {/*    return <Button onClick={(e) => {*/}
                            {/*        chooseCollection(e, c);*/}
                            {/*    }} classNames={cl}>*/}
                            {/*        {c.name}*/}
                            {/*    </Button>*/}
                            {/*})}*/}
                        </div>
                    </InputRow>
                    <InputRow title="Artist Wallet Address"
                              description="The wallet that should receive the payment and Royalties on 2nd market"
                              required>
                        <Input onChange={(e) => {
                            setNFTDataAttr('artist', e.target.value)
                        }} disabled={isLoading} placeholder="e.g. 0x..."/>
                    </InputRow>
                    {isERC721 === false &&
                        <InputRow title="Supply" description="The number of copies that can be minted." required>
                            <Input onChange={(e) => {
                                setNFTDataAttr('supply', e.target.value)
                            }} disabled={isLoading} type="number" placeholder="1" classNames="w-50"/>
                        </InputRow>}
                    <div className="d-flex justify-content-between">
                        {isLoading ? (
                            <p>{trxMessage}</p>
                        ) : (
                            <>
                                <div className="d-flex">
                                    {isAdmin ?
                                        <Button disabled={isLoading} classNames="btn btn-primary" onClick={mintNFT}>Create
                                            Now</Button> : null}
                                </div>
                                <div className="d-flex">
                                    <Button classNames="btn btn-outline-primary">Cancel</Button>
                                </div>
                            </>
                        )}
                    </div>
                </form>
            </div>
        </div>
    )
}

export default Minting